import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Jumbotron, Button, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faCog } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import exitIntent from 'exit-intent';
import {Checkmark} from 'react-checkmark';
import {Bar} from 'react-chartjs-2';
import { gql } from "apollo-boost";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import fetch from 'cross-fetch';

const getPollQuery = gql`
  query($id: ID){
    poll(id: $id){
      name
      domain
      displayName
      theme
      privacyPolicy
      pollToolID
      websiteName
      offerTarget
      autoRedirect
      redirectTime
      landTopText
      landHeadline
      landSubhead
      landImage
      landYes
      landNo
      landSocialBefore
      landSocialAfter
      emailHead
      emailButton
      emailSubHead
      emailSLAPI
      emailSLHash
      emailSLList
      landHeaderCode
      landFooterCode
      loadHeadline
      loadSubhead
      loadWaitMessage
      loadOfferHeadline
      loadImage
      loadOfferButton
      loadOfferLink
      loadHeaderCode
      loadFooterCode
      timestamp
    }
  }
`
const logAnalyticsMutation = gql `
  mutation ($pollId: String!, $visits: Int, $conversions: Int){
    logAnalytics (pollId: $pollId, visits: $visits, conversions: $conversions){
      id
    }
  }
`

var pollVote = function(uuid, value) {
  if (value === "Yes" || value === "No") {
    var xhttp = new XMLHttpRequest();
    xhttp.open("GET", "https://polls.thefasttracks.com/server/server.php?method=logResponse&UUID=" + uuid + "&Response=" + value, true);
    xhttp.send();
  }
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      landing: true,
      theme: 'pulse',
      privacyPolicy: '',
      pollToolID: '0',
      popup: false,
      options: false,
      popupFired: false,
      topText: 'Urgent Poll',
      headline: 'Curious Question Goes Here...',
      subhead: 'And Subheadline Goes Here...',
      image: 'https://polls.thefasttracks.com/funnels/img/tft1.png',
      landimage: "",
      yes: 'I Agree With That!',
      no: 'Are You Out Of Your Mind?',
      social: "12,788",
      socialBefore: "You'll See The Results Of Over",
      socialAfter: 'Voters On The Next Page',
      emailHead: "Enter Your Email To See The Results Of This Urgent Poll",
      emailButton: "Show Me The Results.",
      emailSubHead: "As soon as you submit your email, you'll be taken to the results. The results of this poll are quite shocking right now...",
      websiteName: '[Your Website Name]',
      voteHeadline: 'Thank You, Your Vote Has Been Added',
      voteSubhead: 'Here Are The Results Of The Poll',
      waitMessage: 'Wait, Before You Go...',
      offerHeadline: 'Headline Of Offer You Are Promoting Here',
      loadImage: "",
      offerButton: 'Click Here To Solve All Your Problems',
      offerLink: '',
      redirectTime: 20,
      timestamp: '',
      yesTally: 0,
      noTally: 0,
      labels: ['Yes', 'No'],
      datasets: [
        {
          label: 'Responses',
          backgroundColor: ['rgba(127,255,127,1)','rgba(255,127,127,1)'],
          borderColor: 'rgba(0,0,0,1)',
          borderWidth: 2,
          data: [9355, 3433]
        }
      ],
      container: "container",
      editor: false,
      analyticsLogged: false,
      emailAddress: ''
    };

    this.setTheme = this.setTheme.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.popup = this.popup.bind(this);
    this.options = this.options.bind(this);
    this.popupSubmit = this.popupSubmit.bind(this);
    this.togglePage = this.togglePage.bind(this);
    this.getStyle = this.getStyle.bind(this);
    this.doJumbotron = this.doJumbotron.bind(this);
    this.getQueryVariable = this.getQueryVariable.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  componentDidMount() {
    //console.log(this.getQueryVariable['dash']);
    if (this.getQueryVariable('dash')==='true') {
      this.setState(
        {
          container: "container-fluid",
          editor: true,
          popupFired: true
        }
      );
    }
    if (this.getQueryVariable('mode')==='loading') {
      this.setState(
        {
          landing: false
        }
      );
    }
    if (this.getQueryVariable('mode')==='landing') {
      this.setState(
        {
          landing: true
        }
      );
    }
    if (this.getQueryVariable('mode')==='email') {
      this.setState(
        {
          landing: true,
          popup: true
        }
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.timestamp !== nextState.timestamp) {
      return true;
    }
    if (this.state.container !== nextState.container) {
      return true;
    }
    if (this.state.popup !== nextState.popup) {
      return true;
    }
    if (this.state.landing !== nextState.landing) {
      return true;
    }
    if (this.state.editor !== nextState.editor) {
      return true;
    }
    if (this.state.social !== nextState.social) {
      return true;
    }
    if (this.state.emailHead !== nextState.emailHead) {
      return true;
    }
    if (this.state.emailButton !== nextState.emailButton) {
      return true;
    }
    if (this.state.emailSubHead !== nextState.emailSubHead) {
      return true;
    }
    if (this.props.data !== nextProps.data) {
      return true;
    }
    return false;
  }

  updateState() {
    console.log('Updating State');
    if (this.props.data.poll !== undefined) {
      let poll = this.props.data.poll;
      //console.log(poll.id);
      //alert(poll.landImage);
      if (!this.state.analyticsLogged && this.getQueryVariable('dash')!=='true') {
        let id = this.getQueryVariable('id');
        console.log('Not in dashboard mode, poll id: ' + id);
        console.log(this.props.logAnalyticsMutation);
        /*this.props.logAnalyticsMutation({
          variables: {
            pollId: id,
            visits: 1
          }
        });*/
      }
      this.props.data.startPolling(500);
      this.setState ({
        id: poll.id,
        theme: poll.theme,
        privacyPolicy: poll.privacyPolicy,
        pollToolID: poll.pollToolID,
        topText: poll.landTopText,
        headline: poll.landHeadline,
        subhead: poll.landSubhead,
        landImage: poll.landImage,
        yes: poll.landYes,
        no: poll.landNo,
        socialBefore: poll.landSocialBefore,
        socialAfter: poll.landSocialAfter,
        emailHead: poll.emailHead,
        emailButton: poll.emailButton,
        emailSubHead: poll.emailSubHead,
        websiteName: poll.websiteName,
        voteHeadline: poll.loadHeadline,
        voteSubhead: poll.loadSubhead,
        waitMessage: poll.loadWaitMessage,
        offerHeadline: poll.loadOfferHeadline,
        offerLink: poll.loadOfferLink,
        redirectTime: poll.redirectTime,
        loadImage: poll.loadImage,
        offerButton: poll.loadOfferButton,
        timestamp: poll.timestamp,
        analyticsLogged: true
      });
    }

  }

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    console.log('Query variable %s not found', variable);
  }

  setTheme(event) {
    this.setState({theme: event.target.value});
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    console.log(value);
    this.setState({
      [name]: value
    });
  }

  popup(response) {
    //console.log(this.state.pollToolID);
    let pollID = this.state.pollToolID;
    if (pollID !== '0') {
      //console.log(pollID);
      pollVote(pollID, response);
    }
    this.setState({popup: !this.state.popup});
  }

  options() {
    this.setState({options: !this.state.options});
  }

  popupSubmit() {
    let api = atob(this.props.data.poll.emailSLAPI);
    let hash = atob(this.props.data.poll.emailSLHash);
    let list = this.props.data.poll.emailSLList;
    let email = this.state.emailAddress;
    fetch('https://thefasttracks.com/ftfespapi/sendlane.php?api=' + api + '&hash=' + hash + '&list=' + list + '&email=' + email);
    this.setState({popup: !this.state.popup, landing: false, popupFired: true});
  }

  togglePage() {
    this.setState({landing: !this.state.landing});
  }

  getStyle() {
    let data = this.props.data;
    if (data.poll !== undefined) {
      return (<link href={"https://stackpath.bootstrapcdn.com/bootswatch/4.5.0/" + this.state.theme + "/bootstrap.min.css"} rel="stylesheet" crossorigin="anonymous" />);
    }
  }

  doJumbotron() {
    let data = this.props.data;
    //let topText = '';
    if(data.loading){
      //console.log('loading data');
      //topText = "loading";
    } else {
      //console.log('data loaded');
      //console.log(data);
      /*topText = data.poll.landTopText;
      console.log(topText);*/
      if (this.state.yesTally === 0 && this.state.noTally === 0) {
        let tempYes = 0;
        let tempNo = 0;
        fetch('https://polls.thefasttracks.com/charting/socialProof2.php?uuid=' + this.state.pollToolID)
        .then(res => {
          if (res.status >= 400) {
            throw new Error("Bad response from server");
          }
          return res.text();
        })
        .then(user => {
          this.setState ({
            social: user
          });
        })
        .catch(err => {
          console.error(err);
        });
        fetch('https://polls.thefasttracks.com/server/server.php?method=showResponses&UUID=' + this.state.pollToolID)
        .then(res => {
          if (res.status >= 400) {
            throw new Error("Bad response from server");
          }
          return res.json();
        })
        .then(user => {
          user.map ( (instance) => {
            //console.log(instance.Response);
            if (instance.Response === "Yes") {
              tempYes++;
            } else {
              tempNo++;
            }
          });
          /*this.setState({
            yesTally: this.state.yesTally + tempYes,
            noTally: this.state.noTally + tempNo
          })*/
          fetch('https://polls.thefasttracks.com/server/server.php?method=getAdjustments&UUID=' + this.state.pollToolID)
          .then(res => {
            if (res.status >= 400) {
              throw new Error("Bad response from server");
            }
            return res.json();
          })
          .then(user => {
            user.map ( (instance) => {
              //console.log(instance);
              if (instance.Response === "Yes") {
                tempYes += parseInt (instance.Adjustment, 10);
              } else {
                tempNo += parseInt (instance.Adjustment, 10);
              }
            });
            this.setState({
              yesTally: tempYes,
              noTally: tempNo,
              datasets: [
                {
                  label: 'Responses',
                  backgroundColor: ['rgba(127,255,127,1)','rgba(255,127,127,1)'],
                  borderColor: 'rgba(0,0,0,1)',
                  borderWidth: 2,
                  data: [tempYes, tempNo]
                }
              ]
            })
          })
          .catch(err => {
            console.error(err);
          });
        })
        .catch(err => {
          console.error(err);
        });

      }

      this.updateState();
    }
    let imageStart = "url('";
    let imageEnd = "')";
    const removeExitIntent = exitIntent({
      threshold: 1,
      maxDisplays: 2,
      eventThrottle: 100,
      onExitIntent: () => {
        if (!this.state.popupFired) {
          this.setState({popup: true, popupFired: true});
        }
      }
    })
    if (this.state.landing) {
      return(
        <div id="jumboLoad" className="hidden d-block">
          <h4 className="text-danger">{this.state.topText}</h4>
          <h1>{this.state.headline}</h1>
          <h2>{this.state.subhead}</h2>
          <Row className="justify-content-center">
            <Col sm="12">
              <img
                src={this.state.landImage}
                className="mb-4"
                style={{display: "block", marginLeft: "auto", marginRight: "auto", maxWidth: "80%", height: "auto"}}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="2">
            </Col>
            <Col sm="4" className="mb-4">
              <Button color="success" size="lg" block onClick={() => this.popup('Yes')}>
                <span className="h1"><FontAwesomeIcon icon={faCheckSquare} /> Yes!</span>
                <br/>
                <span className="h5">{this.state.yes}</span>
              </Button>
            </Col>
            <Col sm="4" className="mb-4">
              <Button color="danger" size="lg" block onClick={() => this.popup('No')}>
                <span className="h1"><FontAwesomeIcon icon={faCheckSquare} /> No!</span>
                <br/>
                <span className="h5">{this.state.no}</span>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm="12" className="mb-4">
              <p className="h4">{this.state.socialBefore} {this.state.social} {this.state.socialAfter}</p>
            </Col>
          </Row>
          <Row>
            <Col sm="12" className="mb-4">
              <p>{this.state.websiteName} is the sponsor of this poll.<br />All Submissions are stored privately and not available to or shared with any 3rd parties</p>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <p><a href={this.state.privacyPolicy}>Privacy Policy</a></p>
            </Col>
          </Row>
        </div>
      );
    } else {
      removeExitIntent();
      if (!this.state.editor) {
        function goToOffer(offerLink) {
          window.location.href = offerLink;
        }
        setTimeout (function(){goToOffer(this.state.offerLink);}.bind(this), this.state.redirectTime * 1000)
      }
      return (
        <>
          <h1 className="mb-4">{this.state.voteHeadline}</h1>
          <Checkmark size={100}/>
          <h2 className="mt-4">{this.state.voteSubhead}</h2>
          <Row className="justify-content-center mt-4 mb-4">
            <Col sm="12" md="10" lg="8" style={{backgroundColor: "#fff"}}>
            <Bar
              data={this.state}
              options={{
                scales: {
                  yAxes: [{
                    ticks: {
                      beginAtZero: true
                    }
                  }]
                },
                title:{
                  display:true,
                  text:'Responses',
                  fontSize:20
                },
                legend:{
                  display:false,
                  position:'right'
                }
              }}
            />
            </Col>
          </Row>
          <Row className="justify-content-center mt-2 mb-2">
            <Col sm="12" md="10" lg="8">
              <h1 className="text-danger">{this.state.waitMessage}</h1>
              <h2 className="mb-4">{this.state.offerHeadline}</h2>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2 mb-2">
            <Col sm="12" md="10" lg="8">
              <img
                src={this.state.loadImage}
                style={{display: "block", marginLeft: "auto", marginRight: "auto", maxWidth: "80%", height: "auto"}}
              />
            </Col>
          </Row>
          <Row className="justify-content-center mt-2 mb-2">
            <Col sm="12" md="10" lg="8">
              <a href={this.state.offerLink}>
                <Button color="success" size="lg" className="mb-4 mt-4"><span className="h3">{this.state.offerButton}</span></Button>
              </a>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2 mb-2">
            <Col sm="12" md="10" lg="8">
              <p style={{fontSize: 12, marginTop: 50}}>Affiliate Disclaimer: The owner of this site my receive compensation for recommendations made in reference to the products or services on this website.</p>
            </Col>
          </Row>

        </>
      );
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <style>{`
            .hidden {
              display: none;
            }
          `}</style>
          {this.getStyle()}
        </Helmet>
        <div className={this.state.container} style={{transform: "scale(1)"}}>
          <Row className="mt-4 mb-4">
            <Col sm="12" className="text-center">
              <Jumbotron>
                {this.doJumbotron()}
              </Jumbotron>
            </Col>
          </Row>
          <Modal isOpen={this.state.popup} toggle={this.popup} size="lg" classname="hidden d-block">
            <ModalHeader toggle={this.popup} className="text-center" style={{border: "none"}}>
            </ModalHeader>
            <ModalBody className="text-center">
              <div style={{marginLeft: "auto", marginRight: "auto", maxWidth: 650}}>
                <p className="h3 mb-4">
                  {this.state.emailHead}
                </p>
                <InputGroup>
                <Input
                  name="emailAddress"
                  type="text"
                  placeholder="Enter Your Best Email Address Here..."
                  bsSize="lg"
                  onChange={this.handleInputChange} />
                  {/*<Input placeholder="Enter Your Best Email Address Here..." bsSize="lg" />*/}
                  <InputGroupAddon addonType="append">
                    <InputGroupText><FontAwesomeIcon icon={faEnvelope} /></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <br />
                <Button color="danger" size="lg" onClick={this.popupSubmit}><span className="h4">{this.state.emailButton}</span></Button>
                <br />
                <p><FontAwesomeIcon icon={faLock} /> Safe And Secure Submission</p>
                <p className="h5"><strong>{this.state.emailSubHead}</strong></p>
                <br /><br />
              </div>
            </ModalBody>
          </Modal>
        </div>
      </>
    );
  }
}

//export default App;

let pollId = '';
var query = window.location.search.substring(1);
var vars = query.split('&');
for (var i = 0; i < vars.length; i++) {
  var pair = vars[i].split('=');
  if (decodeURIComponent(pair[0]) === 'id') {
    pollId = decodeURIComponent(pair[1]);
  }
}

export default compose(
  graphql(getPollQuery, {
    name: "data",
    options: (props) => {
      return {
        variables: {
          id: pollId
        }
      }
    }
  }),
  graphql(logAnalyticsMutation, {
    name: "logAnalyticsMutation",
    options: (props) => {
      //console.log('The composed poll ID: ' + pollId);
      return {
        variables: {
          pollId: pollId,
          visits: 0,
          conversions: 0
        }
      }
    }
  })
)(App);
